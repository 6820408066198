import { render, staticRenderFns } from "./ManageMenu.vue?vue&type=template&id=86fdffea"
import script from "./ManageMenu.vue?vue&type=script&lang=js"
export * from "./ManageMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports